import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Grid,
  Button,
  Divider,
  Paper,
  Menu,
  MenuItem,
  Typography
} from "@material-ui/core";
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer,
  ReferenceLine
} from 'recharts';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


import image from 'electrodes1.jpg';

import { lang } from '../Languages/lang';

const CustomTooltip = ({ active, payload, label, lang, type }) => {
  if (active && payload && payload.length && payload[0].value) {
    return (
      <Paper style={{width:'80%'}}>
        <Grid container spacing={2} >
          <Grid item xs={12} style={{paddingBottom:'0px'}}>
            <Typography variant="button" style={{fontWeight:700, marginLeft:'10px'}}>{lang["heat_name"] + ': '}</Typography>
            <Typography variant="button">{label}</Typography> 
          </Grid>
          <Grid item xs={12} style={{paddingTop:'0px'}}>
            <Typography variant="button" style={{fontWeight:700, marginLeft:'10px'}}>{lang[type+"_label"] + ': '}</Typography>
            <Typography variant="button">{payload[0].value+lang[type+"_units"].replace('(','').replace(')','')}</Typography>           
          </Grid>
        </Grid>
      </Paper>
    );
  }
  else if (active && payload && payload.length){
    return (
      <Paper style={{width:'80%'}}>
        <Grid container spacing={2} >
          <Grid item xs={12} style={{paddingBottom:'0px'}}>
            <Typography variant="button" style={{fontWeight:700, marginLeft:'10px'}}>{lang["heat_name"] + ': '}</Typography>
            <Typography variant="button">{label}</Typography> 
          </Grid>
          <Grid item xs={12} style={{paddingTop:'0px'}}>
            <Typography variant="button" style={{fontWeight:700, marginLeft:'10px', color:'#E64A19'}}>{lang["extension_name"]}</Typography>         
          </Grid>
        </Grid>
      </Paper>
    );
  }
  return null;
};

const data = [
  {
    name: '101',
    consumption:0.94,
    length: 297.3,
    fisure: 13.2,
  },
  {
    name: '102',
    consumption:0.91,
    length: 283.4,
    fisure: 8.3,
  },
  {
    name: '103',
    consumption:1.18,
    length: 254.4,
    fisure: 3.8,
  },
  {
    name: '104',
    consumption:0.88,
    length: 247.4,
    fisure: 4.2,
  },
  {
    name: '105',
    consumption:0.93,
    length: 241.1,
    fisure: 7.5,
  },
  {
    name: '106',
    consumption:null,
    length: 310.7,
    fisure: 7.1,
  },
  {
    name: '107',
    consumption:0.98,
    length: 302.0,
    fisure: 9.3,
  },
  {
    name: '108',
    consumption:0.99,
    length: 294.5,
    fisure: 8.6,
  },
  {
    name: '109',
    consumption:1.24,
    length: 244.8,
    fisure: 0.0,
  },
];

const useStyles = makeStyles((theme) => ({
  divider:{
    marginBottom: theme.spacing(2),
  },
}));

export default function Work(props) {
  const { divider } = useStyles();

  const specLang = props.lang || 'es';

  const phases = [1,2,3]

  const options = [
    'consumption',
    'length',
    'fisure'
  ]
  const domain = {
    consumption: [0.5, 1.5],
    length: [200, 350],
    fisure: [0,20],
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuOption, setMenuOption] = React.useState(options[0]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event,text) => {
    if (text!=='backdropClick'){
      setMenuOption(text)
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <Grid container spacing={4} style={{width:'70%', margin:'auto'}}>
        {phases.map((phase,idx) =>{
            return (
              <Grid item xs={3} key={idx}>
                <Button
                  variant="contained"
                  color={phase === 1 ? "primary" : "secondary"}
                  style={{ width: "80%" }}
                >
                  {lang[specLang].phase + " " + phase}
                </Button>
              </Grid>
            )
          })
        }
        <Grid item xs={3}>
          <Button 
            startIcon={<KeyboardArrowDownIcon style={{marginRight:'20px'}}/>}
            aria-controls="simple-menu" 
            aria-haspopup="true" 
            onClick={handleClick}
            variant="contained"
            color={"primary"}
            style={{ width: "100%" }}
          >
            {lang[specLang][menuOption]}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {options.map((option,idx)=>{
              return (
                <MenuItem 
                  key={idx} 
                  style={{minWidth:'120px'}}
                  onClick={e => handleClose(e, option)}>{lang[specLang][option]}
                </MenuItem>
              )
            })}
          </Menu>
        </Grid>
      </Grid>
      <Divider className={divider}/>
      <Grid container spacing={4} style={{width:'100%', margin:'auto'}}>
        <img src={image} style={{width:'85%',margin:'auto'}}/>
      </Grid>
      <Divider className={divider}/>
      <Grid container spacing={4} style={{width:'83%', height:'300px', margin:'auto',marginRight:'10%'}}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <ReferenceLine
              strokeWidth={3}
              stroke="#E64A19"
              x={'106'}
              key={1}
              isFront={true}
            ></ReferenceLine>
            <CartesianGrid strokeDasharray="3 3" />
            {/* <XAxis dataKey="name" angle={-40} dy={5} label='hola'/> */}
            <XAxis 
              dataKey="name" 
              height={40}
              label={{
                value: lang[specLang].heat_label,
                position: "insideBottom",
                dy: 8,
              }}
            />
            <YAxis 
              domain={domain[menuOption]}
              label={{
                value: lang[specLang][menuOption+"_label"]+lang[specLang][menuOption+"_units"],
                angle: -90,
                dy: 35+2.4*(lang[specLang][menuOption+"_label"]+lang[specLang][menuOption+"_units"]).length,
                dx: -10,
                position: "insideLeft",
              }}
            />
            <Tooltip filterNull={false} content={<CustomTooltip lang={lang[specLang]} type={menuOption}/>} />
            <Line 
              connectNulls
              type="monotone" 
              dataKey={menuOption}
              stroke="#303F9F"
            />
          </LineChart>
        </ResponsiveContainer>
      </Grid>
    </div>
  );
}