export const lang = {
    en:{
        phase: 'Phase',
        consumption: 'Consumption',
        length: "Length",
        fisure: "Fisures",
        consumption_label: "Consumption",
        consumption_units: " (kg/Tm)",
        length_label: "Electrode Length",
        length_units: " (cm)",
        fisure_label: "Fisure Length",
        fisure_units: " (cm)",
        heat_label: "Heat Number",
        heat_name: "Heat",
        extension_name: "Extension",
    },
    es:{
        phase: 'Fase',
        consumption: "Consumo",
        length: "Longitud",
        fisure: "Grietas",
        consumption_label: "Consumo",
        consumption_units: " (kg/Tm)",
        length_label: "Longitud del electrodo",
        length_units: " (cm)",
        fisure_label: "Longitud de las fisuras",
        fisure_units: " (cm)",
        heat_label: "Número de Colada",
        heat_name: "Colada",
        extension_name: "Alargamiento",
    }
}