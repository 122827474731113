/* add css module styles here (optional) */

const mainGrid = {
    minHeight: "100vh",
}

const subGrid = {
    minWidth: "380px",
}

const button = {
    textAlign: "center",
    width: "100%",
}
const center = {
    width: "100%",
    textAlign: "center",
}

const margin = {
    width: "100%",
    marginTop: "12px",
    marginBottom: "12px",
}

const extraMargin = {
    marginTop: "24px",
    marginBottom: "24px",
}

const gridBorder = {
    paddingRight: "0px",
    paddingTop: "12px",
}

const gridCenter = {
    paddingRight: "0px",
    paddingTop: "0px",
    color: "#22222252",
}

const logo = {
    maxHeight: "100px",
}

const image = {
    width: "20px",
    height: "20px",
    marginRight: "20px",
}

const typo = {
    fontSize: "12px",
}

const backgroundImage = {
    position: "fixed",
    width: "100%",
    height: "100%",
    zIndex: -100,
}

const container = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#0288D1",
    background: "radial-gradient(circle, #EEEEEE, #BDBDBD)",//"radial-gradient(circle, #039BE5, #01579b)",
    zIndex: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    flexDirection: "column",
    fontFamily: "sans-serif",
}

const card = {
    backgroundColor: "#FAFAFA",
}

export const styles = {
    margin: margin,
    button: button,
    mainGrid: mainGrid,
    subGrid: subGrid,
    center: center,
    extraMargin: extraMargin,
    gridBorder: gridBorder,
    gridCenter: gridCenter,
    logo: logo,
    image: image,
    typo: typo,
    backgroundImage: backgroundImage,
    container: container,
    card: card,
}