import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Button,
  Grid,
  Divider,
  Typography,
  TextField,
  Link,
} from "@material-ui/core";
import GoogleLogo from 'google_logo.png';
import MicrosoftLogo from 'microsoft_logo.jpg';
import YahooLogo from 'yahoo_logo.png';
import FacebookLogo from 'facebook_logo.png';
import { styles } from './styles';

export const FirebaseLogin = (props) => {
  const [page, setPage] = useState('main');

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [repeat, setRepeat] = useState('');

  const preProvider = {
    Google: new props.firebase.GoogleAuthProvider(),
    Microsoft: new props.firebase.OAuthProvider('microsoft.com'),
    Facebook: new props.firebase.FacebookAuthProvider(),
    Yahoo: new props.firebase.OAuthProvider('yahoo.com')
  };

  const provider = Object.keys(preProvider)
  .filter(key => props.providersConfig.includes(key))
  .reduce((obj, key) => {
    obj[key] = preProvider[key];
    return obj;
  }, {});

  const logos = {
    Google: GoogleLogo,
    Microsoft: MicrosoftLogo,
    Facebook: FacebookLogo,
    Yahoo: YahooLogo
  }

  const handleName = (event) => {
    event.preventDefault()
    setName(event.target.value);
  };

  const handleEmail = (event) => {
    event.preventDefault()
    setEmail(event.target.value);
  };

  const handlePassword = (event) => {
    event.preventDefault()
    setPassword(event.target.value);
  };

  const handleRepeat = (event) => {
    event.preventDefault()
    setRepeat(event.target.value);
  };

  const handlePage = (event) => {
    try{
      event.preventDefault()
      setPage(event.currentTarget.id);
    }
    catch (err){
      setPage(event)
    }
  }

  const handleSignInWithEmailAndPassword = () =>{
    if (email && password){
      props.firebase.signInWithEmailAndPassword(props.auth, email, password)
      .then((userCredential) => {
        if (!userCredential.user.emailVerified){
          props.enqueueSnackbar('Email not verified', { variant:'error' })
          return;
        }
        // Signed in 
        const user = userCredential.user;
        props.onLogin(user)
      })
      .catch((error) => {
        const errorCode = error.code;
        switch(errorCode) {
          case 'auth/invalid-email':
            props.enqueueSnackbar('Invalid email', { variant:'error' })
            break;
          case 'auth/user-not-found':
            props.enqueueSnackbar('User does not exist. Sign up first', { variant:'error' })
            break;
          case 'auth/user-disabled':
            props.enqueueSnackbar('User is disabled. Contact the administrator', { variant:'error' })
            break;
          case "auth/wrong-password":
            props.enqueueSnackbar('Wrong password', { variant:'error' })
            break;
          default:
            console.log('ERROR',errorCode)
            props.enqueueSnackbar('Error on login. Try again later', { variant:'error' })
        }
      });
    }
    else{
      props.enqueueSnackbar('Please fill all fields', { variant:'warning' })
    }
  }

  const handleCreateUserWithEmailAndPassword = () =>{
    if (email && password && name && repeat){
      if (password===repeat){
        props.firebase.createUserWithEmailAndPassword(props.auth, email, password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          user.displayName = name;
          props.firebase.sendEmailVerification(user)
          .then(()=>{
            props.onSingup(user)
            props.enqueueSnackbar('Verification email has been sent', { variant:'info' })
            handlePage('main')
          })
          .catch((error)=>{
            console.log('ERROR ON EMAIL VERIFICATION',error)
            props.enqueueSnackbar('Could not send verification email', { variant:'error' })
          })
        })
        .catch((error) => {
          console.log('ERROR',error)
          const errorCode = error.code;
          switch(errorCode) {
            case 'auth/weak-password':
              props.enqueueSnackbar('The password is too weak', { variant:'error' })
              break;
            case 'auth/invalid-email':
              props.enqueueSnackbar('Invalid email', { variant:'error' })
              break;
            case 'auth/email-already-in-use':
              props.enqueueSnackbar('Email already in use', { variant:'error' })
              break;
            default:
              console.log('ERROR',errorCode)
              props.enqueueSnackbar('Error on sign up. Try again later', { variant:'error' })
          }
        });  
      }
      else{
        props.enqueueSnackbar('Passwords must match', { variant:'warning' })
      }
    }
    else{
      props.enqueueSnackbar('Please fill all fields', { variant:'warning' })
    }
  }
  
  const handleSendPasswordResetEmail = () =>{
    props.firebase.sendPasswordResetEmail(props.auth, email)
    .then(() => {
      props.enqueueSnackbar('Password reset email sent!', { variant: 'info' })
      handlePage('main')
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      props.enqueueSnackbar('Error sending reset email. Try again later', { variant: 'error' })
    });
  }

  const handleSignInWithPopup = (event) => {
    props.firebase.signInWithPopup(props.auth, provider[event.currentTarget.id])
    .then((userCredential) => {
      if (!userCredential.user.emailVerified){
        props.enqueueSnackbar('Email not verified', { variant:'error' })
        return;
      }
      // Signed in 
      const user = userCredential.user;
      props.onLogin(user)
    }).catch((error) => {
      const errorCode = error.code;
      switch(errorCode) {
        case 'auth/invalid-email':
          props.enqueueSnackbar('Invalid email', { variant:'error' })
          break;
        case 'auth/user-not-found':
          props.enqueueSnackbar('User does not exist. Sign up first', { variant:'error' })
          break;
        case 'auth/user-disabled':
          props.enqueueSnackbar('User is disabled. Contact the administrator', { variant:'error' })
          break;
        default:
          console.log('ERROR',errorCode)
          props.enqueueSnackbar('Error on login. Try again later', { variant:'error' })
      }
    });
  }

  const handleSignUpWithPopup = (event) => {
    props.firebase.signInWithPopup(props.auth, provider[event.currentTarget.id])
    .then((userCredential) => {
      if (!userCredential.user.emailVerified){
        const user = userCredential.user;
        props.enqueueSnackbar('Email not verified', { variant:'error' })
        props.firebase.sendEmailVerification(user)
        .then(()=>{
          props.onSingup(user)
          props.enqueueSnackbar('Verification email has been sent', { variant:'info' })
          handlePage('main')
        })
        .catch((error)=>{
          console.log('ERROR ON EMAIL VERIFICATION',error)
          props.enqueueSnackbar('Could not send verification email', { variant:'error' })
        })
        return;
      }
      // Signed in 
      const user = userCredential.user;
      props.onSingup(user)
      handlePage('main')
    }).catch((error) => {
      const errorCode = error.code;
      switch(errorCode) {
        case 'auth/invalid-email':
          props.enqueueSnackbar('Invalid email', { variant:'error' })
          break;
        case 'auth/user-not-found':
          props.enqueueSnackbar('User does not exist. Sign up first', { variant:'error' })
          break;
        case 'auth/user-disabled':
          props.enqueueSnackbar('User is disabled. Contact the administrator', { variant:'error' })
          break;
        default:
          console.log('ERROR',errorCode)
          props.enqueueSnackbar('Error on login. Try again later', { variant:'error' })
      }
    });
  }

  const customLoginButtons = Object.keys(provider).map((element)=>
    (
      <div style={styles.margin}>
        <Button 
          type="submit" 
          key={element}
          id={element}
          variant="contained" 
          size="large" 
          color="primary"
          style={styles.button}
          onClick={handleSignInWithPopup}
          >
              <img src={logos[element]} style={styles.image}/>
              Continue with {element}
        </Button>
      </div>
    )
  )

  const customSignupButtons = Object.keys(provider).map((element)=>
    (
      <div style={styles.margin}>
        <Button 
          type="submit" 
          key={element}
          id={element}
          variant="contained" 
          size="large" 
          color="primary"
          style={styles.button}
          onClick={handleSignUpWithPopup}
          >
              <img src={logos[element]} style={styles.image}/>
              Continue with {element}
        </Button>
      </div>
    )
  )

  return (
    <div style={styles.container}>
      {props.backgroundImage ? (
        <img src={props.backgroundImage} style={styles.backgroundImage}></img>
      ) : null}
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={styles.mainGrid}
      >
        <Grid item xs={4} style={styles.subGrid}>
          {page==='main' ? (
            <Card>
              <CardContent style={styles.card}>
                <div style={styles.center}>
                  {props.mainLogo ? (
                    <img src={props.mainLogo} style={styles.logo}/>
                  ) : (
                    null
                  )}
                </div>
              <h2 style={styles.center}>Login in to your account</h2>
              {props.providersConfig.includes('Mail') ? (
                <div>
                  <div style={styles.margin}>
                    <TextField name="Email" value={email} onChange={handleEmail} label="Email" style={styles.center}/>
                  </div>
                  <div style={styles.margin}>
                    <TextField name="Password" value={password} onChange={handlePassword} label="Password" type="password" style={styles.center}/>
                  </div>
                  <div style={styles.margin}>
                    <Button 
                      type="submit" 
                      variant="contained" 
                      size="large" 
                      color="primary" 
                      style={styles.button}
                      onClick={handleSignInWithEmailAndPassword}
                    >
                        Log in
                    </Button>
                  </div>
                  {props.providersConfig.length>1? (
                    <div style={styles.extraMargin}>
                      <Grid container spacing={0}>
                        <Grid item xs={5} style={styles.gridBorder}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={2} style={styles.gridCenter}>
                            <Typography align='center'>or</Typography>
                        </Grid>
                        <Grid item xs={5} style={styles.gridBorder}>
                            <Divider/>
                        </Grid>
                      </Grid>
                    </div>
                  )
                  :
                  null
                  }
                </div>
              ) 
              :
              null
              }
              {customLoginButtons.map((button)=>{
                return button
              })}
              <div style={styles.margin}/>
              <Divider/>
              <div style={styles.extraMargin}/>
              {props.providersConfig.includes('Mail') ? (
                <Typography>
                <Link href="" onClick={handlePage} id='forgot' style={styles.typo}>
                  Forgot your password? <span></span>
                </Link>
              </Typography>
              )
              :
              null
              }
              <Typography variant='caption'>
                New user? <span></span>
                <Link href="" onClick={handlePage} id='started' style={styles.typo}>
                  Sign up 
                </Link>
              </Typography>
              </CardContent>
            </Card>
          ) 
          : 
          ( 
            page==='signup' ? (
              <Card>
                <CardContent style={styles.card}>
                  <div style={styles.center}>
                    {props.mainLogo ? (
                      <img src={props.mainLogo} style={styles.logo}/>
                    ) : (
                      null
                    )}
                  </div>
                  <h2 style={styles.center}>Sign Up</h2>
                  <div style={styles.margin}>
                    <TextField name="Name" value={name} onChange={handleName} label="Name" style={styles.center} required/>
                  </div>
                  <div style={styles.margin}>
                    <TextField name="Email" value={email} onChange={handleEmail} label="Email" style={styles.center} required/>
                  </div>
                  <div style={styles.margin}>
                    <TextField name="Password" value={password} onChange={handlePassword} label="Password" type="password" style={styles.center} required/>
                  </div>
                  <div style={styles.margin}>
                    <TextField name="Repeat" value={repeat} onChange={handleRepeat} label="Repeat Password" type="password" style={styles.center} required/>
                  </div>
                  <div style={styles.margin}>
                    <Button 
                      type="submit" 
                      variant="contained" 
                      size="large" 
                      color="primary" 
                      style={styles.button}
                      onClick={handleCreateUserWithEmailAndPassword}
                    >
                        Sign up
                    </Button>
                  </div>
                  <div style={styles.margin}/>
                  <Divider/>
                  <div style={styles.extraMargin}/>
                  <Typography>
                    <Link href="" onClick={handlePage} id='main' style={styles.typo}>
                      Return to log in
                    </Link>
                  </Typography>
                </CardContent>
              </Card>
            )
            : 
            (
              page==='forgot' ? (
                <Card>
                  <CardContent style={styles.card}>
                    <div style={styles.center}>
                      {props.mainLogo ? (
                        <img src={props.mainLogo} style={styles.logo}/>
                      ) : (
                        null
                      )}
                    </div>
                    <h2 style={styles.center}>Reset your password</h2>
                    <div style={styles.margin}>
                      <TextField name="Email" value={email} onChange={handleEmail} label="Email" style={styles.center}/>
                    </div>
                    <div style={styles.margin}>
                      <Button 
                        type="submit" 
                        variant="contained" 
                        size="large" 
                        color="primary" 
                        style={styles.button}
                        onClick={handleSendPasswordResetEmail}
                      >
                        Send password reset email
                      </Button>
                    </div>
                    <div style={styles.margin}/>
                    <Divider/>
                    <div style={styles.extraMargin}/>
                    <Typography>
                      <Link href="" onClick={handlePage} id='main' style={styles.typo}>
                        Return to log in
                      </Link>
                    </Typography>
                  </CardContent>
                </Card>
              )
              : 
              (
                page==='started' ? (
                  <Card>
                    <CardContent style={styles.card}>
                      <div style={styles.center}>
                        {props.mainLogo ? (
                          <img src={props.mainLogo} style={styles.logo}/>
                        ) : (
                          null
                        )}
                      </div>
                      <h2 style={styles.center}>Get started</h2>
                      {props.providersConfig.includes('Mail') ? (
                        <div style={styles.margin}>
                          <Button 
                            type="submit" 
                            variant="contained" 
                            size="large" 
                            color="primary" 
                            style={styles.button}
                            onClick={handlePage} id='signup'
                          >
                            Sign up with email
                          </Button>
                          
                        </div>
                      )
                      :
                      null
                      }
                      {customSignupButtons.map((button)=>{
                        return button
                      })}
                      <div style={styles.margin}/>
                      <Divider/>
                      <div style={styles.extraMargin}/>
                      <Typography>
                        <Link href="" onClick={handlePage} id='main' style={styles.typo}>
                          Return to log in
                        </Link>
                      </Typography>
                    </CardContent>
                  </Card>
                ): null
              )
            )
          )}
        </Grid>
      </Grid>
    </div>
  )
}
